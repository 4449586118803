import { Pipe, PipeTransform } from '@angular/core';
import { GlobalElementService } from '../services/global-element.service';

@Pipe({
  name: 'brandTranslate'
})
export class BrandTranslatePipe implements PipeTransform {

  constructor(public globalElement: GlobalElementService) {}

  transform(value: string): string {
    return this.globalElement.brandTranslate(value);
  }

}
