import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbsPipe } from './abs.pipe';
import { BrandTranslatePipe } from './brand-translate.pipe';
import { NumberFormatPipe } from './number-format.pipe';



@NgModule({
  declarations: [AbsPipe, NumberFormatPipe, BrandTranslatePipe],
  imports: [
    CommonModule
  ],
  exports: [AbsPipe, NumberFormatPipe, BrandTranslatePipe]
})
export class PipesModule { }
