import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalElementService } from '../global-element.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuardService implements CanActivate {

  constructor(
    public authenticationService: AuthenticationService,
    private globalElement: GlobalElementService,
    private router: Router
  ) { }

  canActivate() {
    return this.checkPermission();
  }

  checkPermission() {
    return this.authenticationService.getUser().then(employee => {
      let hasPermision = false;
      if (employee != null) {
        hasPermision = this.globalElement.permissionIsAdmin(employee.groups);
      }
      if (!hasPermision) {
        this.router.navigate(['/vehicles/admin'], { replaceUrl: true });
      }
      return hasPermision;
    });
  }
}
