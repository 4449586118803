import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { String } from 'typescript-string-operations';
import { EmployeeGroups } from '../models/EmployeeGroups';
import { AuthenticationService } from './auth/authentication.service';
import { ErrorScreenService } from './commons/error-screen.service';
import { StorageService } from './commons/storage.service';
import { TranslateConfigService } from './translate-config.service';
const { Toast } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class GlobalElementService {

  public CATEGORY_SICK = 1;
  public CATEGORY_COMPENSATION = 2;
  public CATEGORY_HOMEWORK = 3;
  public CATEGORY_HOLIDAY = 4;
  public DAY = 1;
  public PENDING_DAY = 1;
  public GROUP_ADMIN = 1;
  public GROUP_FINANCING = 2;
  public GROUP_SALES = 3;
  public GROUP_VIEW_PROFILE = 4;
  public GROUP_USER = 5;
  public LIMIT_TABLE = 10;
  public PERIODICITY_YEAR = 365;
  public VEHICLE_REQUEST_TYPE_RENTING = 2;
  public VEHICLE_REQUEST_TYPE_COMPANY = 1;
  // Variables
  calendarMonthFormat = 'MMMM yyyy';
  calendarInitDate = new Date(2000, 0, 1);
  showFilterCompany = false;
  navigateVehicleUser = '';
  typeRequest: string;
  mode = 'user';
  // Filtros vehiculo
  filterYear: any[] = [];
  filterBrand: any[] = [];
  filterModel: any[] = [];
  private filterVehiclesSubject = new Subject<any>();
  constructor(
    private alertCtrl: AlertController,
    private translateService: TranslateConfigService,
    private authService: AuthenticationService,
    private errorScreenService: ErrorScreenService,
    private storageService: StorageService) { }

  async errorAlert(message: string) {
    const alert = await this.alertCtrl.create({
      header: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  getDaysWeek() {
    return [
      this.translateService.getTranslate('days.Sunday'),
      this.translateService.getTranslate('days.Monday'),
      this.translateService.getTranslate('days.Tuesday'),
      this.translateService.getTranslate('days.Wednesday'),
      this.translateService.getTranslate('days.Thursday'),
      this.translateService.getTranslate('days.Friday'),
      this.translateService.getTranslate('days.Saturday')
    ];
  }

  getMonths() {
    return [
      this.translateService.getTranslate('short_month.January'),
      this.translateService.getTranslate('short_month.February'),
      this.translateService.getTranslate('short_month.March'),
      this.translateService.getTranslate('short_month.April'),
      this.translateService.getTranslate('short_month.May'),
      this.translateService.getTranslate('short_month.June'),
      this.translateService.getTranslate('short_month.July'),
      this.translateService.getTranslate('short_month.August'),
      this.translateService.getTranslate('short_month.September'),
      this.translateService.getTranslate('short_month.October'),
      this.translateService.getTranslate('short_month.November'),
      this.translateService.getTranslate('short_month.December')
    ];
  }

  permissionShowNotifications(group: EmployeeGroups[]) {
    let hasPermission = group.findIndex( g => g.groups.id !== this.GROUP_USER) >= 0;
    return hasPermission;
  }

  permissionViewDetail(group: EmployeeGroups[]) {
    let hasPermission = false;
    group.forEach(element => {
      const index = element.groups.permissions.findIndex(permission => permission.permissions.id === this.GROUP_VIEW_PROFILE);
      if (index >= 0) {
        hasPermission = true;
        return;
      }
    });
    return hasPermission;
  }

  permissionIsAdmin(groups: EmployeeGroups[]) {
    const index = groups.findIndex(element => element.groups.id === this.GROUP_ADMIN);
    if (index >= 0) {
      return true;
    }
    return false;
  }

  permissionIsAdminVehicles(groups: EmployeeGroups[]) {
    const index = groups.findIndex(element => element.groups.id === this.GROUP_ADMIN
      || element.groups.id === this.GROUP_FINANCING
      || element.groups.id === this.GROUP_SALES);
    if (index >= 0) {
      return true;
    }
    return false;
  }

  permissionChangeChasis(groups: EmployeeGroups[]) {
    const index = groups.findIndex(element => element.groups.id === this.GROUP_ADMIN
      || element.groups.id === this.GROUP_SALES);
    if (index >= 0) {
      return true;
    }
    return false;
  }

  async toastShort(text: string) {
    await Toast.show({
      text,
      duration: 'short',
      position: 'bottom'
    });
  }

  async toastLong(text: string) {
    await Toast.show({
      text,
      duration: 'long',
      position: 'bottom'
    });
  }

  async showAlert(titulo: string, mensaje: string, boton: string) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: titulo,
      message: mensaje,
      buttons: [boton]
    });
    await alert.present();
  }

  async showConfirm(titulo: string, mensaje: string, botonCancelar: any, botonConfirmar: any) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: titulo,
      message: mensaje,
      buttons: [
        botonCancelar,
        botonConfirmar
      ]
    });
    await alert.present();
  }

  stringIsNullOrEmpty(value) {
    return String.IsNullOrWhiteSpace(value);
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [year, month, day].join('-');
  }

  formatNumber(value) {
    if (value === undefined || value === null) {
      return value;
    }
    return parseFloat(value.toString());
  }

  publishFilterVehicle(data: any) {
    this.filterVehiclesSubject.next(data);
  }

  getObservableFilterVehicle(): Subject<any> {
    return this.filterVehiclesSubject;
  }

  brandTranslate(brand) {
    switch (brand) {
      case 'TY': {
        return 'Toyota';
      }
      case 'LX': {
        return 'Lexus';
      }
      default: {
        return brand;
      }
    }
  }

  async getUserLogin() {
    const currentEmployee = await this.authService.getUser();
    if (currentEmployee == null || this.stringIsNullOrEmpty(currentEmployee.uuid)) {
      // Mensaje para avisar al usuario de que ha caducado la sesion o no se encuentra
      this.errorScreenService.showModal(this.translateService.getTranslate('errors.not_exist_user'), false);
      return null;
    }
    return currentEmployee;
  }

  closeSession(message = this.translateService.getTranslate('errors.comun')) {
    if (this.authService.isAuthenticated()) {
      this.authService.logout();
      this.storageService.clear();
      this.errorScreenService.showModal(message, false);
    }
  }

  saveItemStorage(name, value) {
    this.storageService.setItem(name, value);
  }

  async getItemStorage(name) {
    return await this.storageService.getItem(name);
  }

  saveObjectStorage(name, value) {
    this.storageService.setObject(name, value);
  }

  async getObjectStorage(name) {
    return await this.storageService.getObject(name);
  }

  removeObjectStorage(name) {
    this.storageService.removeObject(name);
  }
}
