import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  public translateText: string;
  private languageDefault = 'es';
  constructor(private translate: TranslateService) { }
  getDefaultLanguage() {
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(this.languageDefault);
    return this.languageDefault;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
  }
  getTranslate(id: string, params = null) {
    this.translateText = '';
    try {
      const subTranslate = this.translate.get(id, params).subscribe((text: string) => {
        if (text !== id) {
          this.translateText = text;
        }
      });
      subTranslate.unsubscribe();
    } catch (error) {
      this.translateText = '';
    }
    return this.translateText;
  }
}
