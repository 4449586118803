import { Pipe, PipeTransform } from '@angular/core';
import { GlobalElementService } from '../services/global-element.service';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  constructor(public globalElement: GlobalElementService) {

  }

  transform(value): string {
    return this.globalElement.formatNumber(value);
  }

}
