import { Component } from "@angular/core";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { MenuController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { FiltersVehicle } from "./models/FiltersVehicle";
import { GlobalElementService } from "./services/global-element.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  showFilterCompany = false;
  filterYear: any[] = [];
  filterBrand: any[] = [];
  filterModel: any[] = [];
  filterApply: FiltersVehicle;
  hasPermission = false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    public menuCtrl: MenuController,
    public globalElement: GlobalElementService
  ) {
    this.initializeApp();
    this.filterApply = new FiltersVehicle();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.setDefaultLang("es");
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  closeMenu(id) {
    if (this.menuCtrl.isOpen(id)) {
      this.menuCtrl.close(id);
    }
  }

  menuFilterOpened() {
    this.showFilterCompany = this.globalElement.showFilterCompany;
    this.filterBrand = this.globalElement.filterBrand;
    this.filterModel = this.globalElement.filterModel;
    this.filterYear = this.globalElement.filterYear;
  }

  filterOrder(orderBy) {
    if (this.filterApply.orderBy === orderBy) {
      this.filterApply.orderBy = "";
      this.callApplyFilter();
      return;
    }
    this.filterApply.orderBy = orderBy;
    this.callApplyFilter();
  }

  filterByCategory(category) {
    if (this.filterApply.category === category) {
      this.filterApply.category = "";
      this.callApplyFilter();
      return;
    }
    this.filterApply.category = category;
    this.callApplyFilter();
  }

  filterByBrand(brand) {
    const index = this.filterApply.brand.indexOf(brand);
    if (index > -1) {
      this.filterApply.brand.splice(index, 1);
    } else {
      this.filterApply.brand.push(brand);
    }
    this.callApplyFilter();
  }

  filterByYear(year) {
    const index = this.filterApply.year.indexOf(year);
    if (index > -1) {
      this.filterApply.year.splice(index, 1);
    } else {
      this.filterApply.year.push(year);
    }
    this.callApplyFilter();
  }

  filterByModel(model) {
    const index = this.filterApply.model.indexOf(model);
    if (index > -1) {
      this.filterApply.model.splice(index, 1);
    } else {
      this.filterApply.model.push(model);
    }
    this.callApplyFilter();
  }

  callApplyFilter() {
    this.globalElement.publishFilterVehicle(this.filterApply);
  }

  async menuVehiclesOpened() {
    const employee = await this.globalElement.getUserLogin();
    if (employee == null) {
      return;
    }
    this.hasPermission = this.globalElement.permissionIsAdmin(employee.groups);
  }

  async menuAccountsOpened() {
    const employee = await this.globalElement.getUserLogin();
    if (employee == null) {
      return;
    }
    this.hasPermission = this.globalElement.permissionIsAdmin(employee.groups);
  }
}
