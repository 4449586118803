
export class FiltersVehicle {
    orderBy: string;
    category: string;
    brand: string[];
    year: string[];
    model: string[];

    constructor() {
        this.orderBy = '';
        this.category = '';
        this.brand = [];
        this.year = [];
        this.model = [];
    }
}