import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../commons/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authState = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private storage: StorageService,
    private platform: Platform,
    public toastController: ToastController
  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }

  async ifLoggedIn() {
    const user = await this.storage.getItem('EMPLOYEE');
    if (user) {
      this.authState.next(true);
    }
  }


  login(user, navigateUrl: string) {
    this.storage.setObject('EMPLOYEE', user).then((response) => {
      this.router.navigate([navigateUrl]);
      this.authState.next(true);
    });
  }

  logout() {
    this.storage.removeObject('EMPLOYEE').then(() => {
      this.authState.next(false);
    });
  }

  isAuthenticated() {
    return this.authState.value;
  }

  async getUser() {
    const user = await this.storage.getObject('EMPLOYEE');
    if (user) {
      return user;
    }

    return null;
  }
}
