import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ErrorScreenPage } from '../../pages/modals/error-screen/error-screen.page';

@Injectable({
    providedIn: 'root'
})
export class ErrorScreenService {

    count = 0;
    modal: HTMLIonModalElement;

    constructor(private modalController: ModalController) { }

    async showModal(message: string, reload = false) {
        this.modal = await this.modalController.create({
            component: ErrorScreenPage,
            cssClass: 'modal-screen',
            backdropDismiss: false,
            keyboardClose: false,
            componentProps: {
                message,
                reload
            }
        });
        if (!this.count) {
            await this.modal.present();
        }
        this.count++;

        return this.modal;
    }

    async hideModal(data: string) {
        this.count--;
        if (this.modal) {
            await this.modal.dismiss();
        }
        return data;
    }
}
