import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error-screen',
  templateUrl: './error-screen.page.html',
  styleUrls: ['./error-screen.page.scss'],
})
export class ErrorScreenPage implements OnInit {

  @Input() message: string;
  @Input() reload: boolean;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  async reloadScreen() {
    await this.modalCtrl.dismiss('reload');
  }

}
